@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap");

body {
  background: #17181f;
  font-family: "Poppins", sans-serif;
}

input,
input:focus,
input:hover,
button,
button:hover,
button:active,
button:focus,
textarea:focus,
textarea:hover,
textarea {
  box-shadow: none !important;
  outline: none !important;
  resize: none !important;
}

a,
a:hover {
  text-decoration: none;
  color: #000;
}

button:disabled {
  cursor: no-drop;
}

.anti-block {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.purple {
  background: #59287a;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-no-drop {
  cursor: no-drop;
}

.copy-tooltip {
  width: 120px !important;
}

.overflow-custom::-webkit-scrollbar {
  width: 5px;
}

.overflow-custom::-webkit-scrollbar-thumb {
  background: #39435e;
  border-radius: 10px;
}

.overflow-custom::-webkit-scrollbar-thumb:hover {
  background: #39435e;
}

.loader {
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -5px;
  margin-top: 5px;
}

.modal-content {
  background: transparent !important;
}

.connect-box {
  height: 100vh;
  background-image: url("../bg-dashboard.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}

.connect-box .alert {
  position: absolute;
  top: 20px;
  font-family: "Poppins", sans-serif;
}

.connect-box .alert span p {
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
}

.connect-box .logo {
  padding: 20px;
}

.connect-box .logo .logo-img {
  font-weight: bold;
  font-size: 25px;
  color: #fff;
  padding: 0 20px;
  text-align: center;
}

.connect-box .logo div {
  background: #39435e;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.connect-box .logo div img {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.connect-box .logo div p {
  font-size: 15px;
  opacity: 80;
}

.connect-box .logo div input[type="text"],
.connect-box .logo div input[type="password"] {
  background: #000;
  border: none;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 10px;
  color: #fff;
  font-size: 15px;
}

.connect-box .logo div .signature-check {
  text-align: left !important;
  padding: 0;
  margin-bottom: 10px;
}

.connect-box .logo div .signature-check label {
  margin-left: 10px;
}

.connect-box .logo div button {
  outline: none;
  border: none;
  padding: 10px 20px;
  background: #000;
  color: #fff;
}

.connect-box .logo div .text-danger,
.connect-box .logo div .text-warning {
  font-size: 13px;
  display: block;
  opacity: 100;
  margin-top: 10px;
  margin-bottom: 0;
}

.modal-info {
  padding: 20px !important;
  text-align: left !important;
  color: #fff;
}

.modal-info .question {
  font-size: 17px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  margin-bottom: 5px;
}

.modal-info .answer {
  font-size: 15px;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

.modal-info .info {
  font-size: 15px;
  margin-top: 20px;
  text-align: justify !important;
}

.modal-mnemonic div button {
  background: #000;
  border: 2px solid #000;
  color: #fff;
  padding: 10px 20px;
  transition: 0.5s;
}

.modal-mnemonic div .active {
  border: 2px solid #fff;
}

.modal-mnemonic div div .alert {
  display: flex;
  flex-wrap: nowrap;
}

.modal-mnemonic div div .alert i {
  font-size: 20px;
  margin-right: 15px;
  color: #000;
  opacity: 0.3;
}

.modal-mnemonic div div input,
.modal-mnemonic div div textarea {
  background: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  display: block;
  width: 100%;
}

.modal-mnemonic div div p {
  margin-bottom: 0;
  text-align: left;
}

.modal-mnemonic div div span {
  display: block;
  text-align: left;
}

.modal-scan i {
  font-size: 30px;
}

.empty-box {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.empty-box div {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.empty-box div span {
  color: #fff;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
}

.alert-box {
  position: fixed;
  z-index: 3;
  top: 20px;
  right: 20px;
}

.alert-box a {
  margin-top: 10px;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border-bottom: 4px solid rgba(0, 0, 0, 0.3);
  display: block;
}

.alert-box a p {
  margin-bottom: 0;
}

.alert-box-white:hover {
  color: white !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-profile,
.modal-scan,
.modal-mnemonic,
.modal-info {
  background: #1f2029;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
}

.modal-profile {
  text-align: left !important;
  padding: 15px;
  color: #fff;
}

.modal-profile .profile {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.modal-profile .profile img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border: 3px solid #fff;
}

.modal-profile .profile input {
  display: none;
}

.modal-profile .profile div {
  margin: 0 auto;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 33px 5px 0 15px;
}

.modal-profile .profile div a {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}

.modal-profile .profile div div {
  margin-top: 10px;
  margin-left: 0;
}

.modal-profile .profile div div button {
  background: #34538a;
  color: #fff;
  font-size: 15px;
  padding: 3px 15px;
  font-family: "Nunito", sans-serif;
  border: 0;
  border-radius: 20px !important;
  margin-right: 10px;
}

.modal-profile .profile div div .close {
  border: 2px solid #fff;
  background: transparent;
  transition: 0.3s;
}

.modal-profile .profile div div .close:hover {
  background: #fff;
  color: #000;
}

.modal-profile .list {
  margin-top: 20px;
}

.modal-profile .list .d-flex {
  padding: 7px;
  transition: 0.4s;
  cursor: pointer;
  color: #fff;
}

.modal-profile .list .d-flex:hover {
  background: rgba(0, 0, 0, 0.4);
}

.modal-profile .list .d-flex .rounded {
  padding: 4px;
  font-size: 20px;
  text-align: center;
  width: 36px;
  height: 36px;
}

.modal-profile .list .d-flex p {
  margin-bottom: 0;
  margin-top: 6px;
  margin-left: 15px;
  color: #fff;
}

.modal-profile .list .d-flex p span {
  margin-left: 10px;
}

.modal-profile .list .header {
  font-size: 17px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.modal-profile .list .header .back {
  margin-right: 10px;
  cursor: pointer;
  color: #fff;
}

.modal-profile .list .mnemonic input {
  background: #000;
  color: #fff;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;
  border: none;
}

.modal-profile .list .blocked .d-flex {
  cursor: default !important;
}

.modal-profile .list .blocked .d-flex p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-profile .list .blocked .d-flex .bi-x-lg {
  margin-left: 10px;
  margin-top: 7px;
  cursor: pointer;
}

.left-section {
  background: #1f2029;
  height: 100vh;
  padding-top: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}

.left-section .section-head {
  background: #1f2029;
  z-index: 4;
  margin-top: -160px;
}

.left-section .profile-box {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 9px 5px 9px;
  margin-top: 10px;
  margin-bottom: -10px;
  width: 100%;
}

.left-section .profile-box .bi {
  display: block;
  z-index: 1;
  position: absolute;
  font-size: 13px;
  top: 45px;
  left: 65px;
}

.left-section .profile-box img {
  cursor: pointer;
  position: absolute;
}

.left-section .profile-box div {
  width: 100%;
}

.left-section .profile-box i {
  font-size: 20px;
  margin-top: 12px;
}

.left-section .profile-box div p {
  font-family: "Nunito", sans-serif;
  margin-top: 17px;
  font-size: 17px;
  color: #fff;
  margin-left: 70px;
  padding-right: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-section .search-box {
  display: flex;
  flex-wrap: nowrap;
}

.left-section .search-box i {
  margin-left: 13px;
  margin-right: -30px;
  margin-top: 7px;
  z-index: 1;
}

.left-section .search-box input {
  display: inline-block;
  font-family: "Nunito", sans-serif;
  width: 100%;
  margin-bottom: 15px;
  padding: 7px 10px 7px 40px;
  border-radius: 5px;
  border: none;
  background: #39435e;
  color: #fff;
}

.left-section .search-box input::placeholder {
  color: #fff;
  opacity: 0.75;
}

.left-section .search-box input:-ms-input-placeholder {
  color: #fff;
  opacity: 0.75;
}

.left-section .search-box input::-ms-input-placeholder {
  color: #fff;
  opacity: 0.75;
}

.left-section hr {
  border-color: #55aeac;
  margin: 15px 0;
}

.left-section .connections-list .connections-head {
  padding: 15px 9px 0 9px;
  margin-top: 0;
}

.left-section .connections-list .connections-head i {
  display: inline-block;
  font-size: 17px;
}

.left-section .connections-list .connections-head p {
  display: inline-block;
  font-size: 17px;
  margin: 0 0 0 10px;
}

.left-section .connections-list button {
  padding: 7px 10px;
  font-size: 14px;
  width: 50%;
  background: #34538a;
  color: #fff;
  border-radius: 20px;
  border: 2px solid #34538a;
  margin-top: 80px;
}

.left-section .connections-list button i {
  margin-right: 5px;
}

.left-section .connections-list .scan {
  background: transparent;
  border: 2px solid #fff;
  transition: 0.4s;
}

.left-section .connections-list .scan:hover {
  background: #fff;
  border: 2px solid #fff;
  color: #000;
}

.left-section .connections-list .qr {
  width: 220px;
  height: 220px;
  border-radius: 20px;
  background: #f2f1f2;
  margin: 0 auto;
  margin-top: 20px;
  padding: 7px;
  cursor: none;
}

.left-section .connections-list .connection {
  width: 100%;
  padding: 10px;
  transition: 0.4s;
  border-radius: 10px;
  margin-top: 5px;
}

.left-section .connections-list .connection:hover {
  background: rgba(255, 255, 255, 0.1);
}

.left-section .connections-list .connection .detail-box {
  display: flex;
  flex-wrap: nowrap;
}

.left-section .connections-list .connection .icon {
  width: 45px;
  height: 45px;
  background: #f2f1f2;
  margin-right: 10px;
  border-radius: 10px;
  text-align: center;
}

.left-section .connections-list .connection .icon i {
  color: #000;
  font-size: 30px;
}

.left-section .connections-list .connection .detail {
  margin-left: 10px;
}

.left-section .connections-list .connection .detail span {
  display: block;
}

.left-section .connections-list .connection .detail span {
  font-size: 15px;
  margin-bottom: 5px;
}

.left-section .connections-list .connection .detail span i {
  font-size: 5px;
  vertical-align: middle;
  margin: 0 5px;
}

.left-section .connections-list .connection .detail span[class="user-agent"] {
  font-size: 14px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.7);
}

.left-section .connections-list .connection button {
  margin-top: 15px;
  border: none;
}

.list-box {
  width: 100%;
  border-radius: 5px;
  transition: 0.4s;
  padding: 10px;
  margin-bottom: 7px;
  display: flex;
  flex-wrap: nowrap;
  color: #fff;
  cursor: pointer;
}

.list-day {
  margin-top: 20px;
  margin-bottom: 10px;
}

.list-day hr {
  border-color: rgba(255, 255, 255, 0.9);
  margin-bottom: -15px;
}

.list-day p {
  color: #000;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
}

.list-box:hover,
.active {
  background: rgba(255, 255, 255, 0.1);
}

.list-box img {
  width: 60px;
  flex-shrink: 0;
}

.list-box img,
.left-section .profile-box img,
.right-section .profile-box img {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
}

.list-box .right {
  width: 100%;
  margin-left: 7px;
}

.list-box .right .opponent {
  display: flex;
  flex-wrap: nowrap;
}

.list-box .right .opponent .address {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  margin-bottom: 0;
}

.list-box .right .opponent .time {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  text-align: right;
}

.list-box .right .preview {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.list-box .right .preview p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -10px;
  margin-bottom: 0;
}

.list-box .right .preview span {
  margin-top: -10px;
  margin-left: 3px;
  font-size: 11px;
  border-radius: 20px;
  background: #5698ca;
  min-width: 20px;
}

.right-section {
  height: 100vh;
  position: relative;
  padding-left: 0;
}

.right-section .dialog {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100vh;
}

.right-section .profile-box {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 9px;
  padding: 20px;
  background: #1f2029;
  height: 100px;
  width: 100%;
}

.right-section .profile-box img {
  flex-shrink: 0;
}

.right-section .profile-box .bi-arrow-left {
  display: none;
}

.right-section .profile-box p {
  font-family: "Poppins", sans-serif;
  margin-top: 17px;
  font-size: 17px;
  color: #fff;
  margin-left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-section .images-preview {
  background: #1f2029;
  padding: 20px;
  margin-bottom: -20px;
}

.right-section .image-preview {
  width: 60px;
  height: 60px;
  margin: 0 5px;
}

.right-section .image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 0;
  cursor: pointer;
}

.right-section .input-chat {
  padding: 30px 0px;
  background: #1f2029;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.right-section .input-chat input[type="text"] {
  background: #1f2029;
  width: 100%;
  border-radius: 12px;
}

.right-section .input-chat button {
  background: transparent;
  color: #fff;
  border: none;
  font-size: 20px;
}

.right-section .input-chat input {
  background-color: #1f2029;
  border: none;
  color: #fff;
  width: 100%;
  display: inline-block;
  margin-left: 20px;
  text-indent: 5px;
}

.right-section .input-chat label {
  border: none;
  color: #fff;
  font-size: 20px;
  display: inline-block;
  margin-right: 0p;
  padding: 5px 10px;
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
}

.right-section .input-chat input[type="file"] {
  display: none;
}

.bubble-box {
  margin-right: -10px;
  overflow-y: auto;
  background: transparent;
  height: 100%;
}

.bubble-box .scroll-down {
  position: fixed;
  z-index: 2;
  background: rgba(31, 32, 41, 0.4);
  color: #fcf4d2;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  bottom: 110px;
  right: 15px;
  cursor: pointer;
}

.bubble-box .day,
.bubble-box .unread {
  background: rgba(255, 255, 255, 0.05);
  color: #fcf4d2;
  padding: 5px 15px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.bubble-box .day p {
  margin-bottom: 0;
}

.bubble-box .opponent .message {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.bubble-box .opponent .message .text {
  background: #1f2029;
  border-radius: 15px 15px 15px 0;
  max-width: 70%;
  word-break: break-word;
  padding: 10px 20px;
  color: #fff;
}

.bubble-box .opponent .message .text-image {
  border-radius: 15px 15px 0 0;
}

.bubble-box .opponent .message .text p {
  color: #fff !important;
}

.bubble-box .opponent .message .image {
  background: #1f2029;
  border-radius: 15px 15px 0 15px;
  max-width: 70%;
}

.bubble-box .opponent .message .image div {
  padding: 5px;
  border-radius: 15px;
}

.bubble-box .opponent .message .image div .more-image {
  width: 120px;
  height: 120px;
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.bubble-box .opponent .message .image div .more-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: 100% 0;
  margin: -5px;
  cursor: pointer;
}

.bubble-box .opponent .message .image div .more-image div {
  width: 120px;
  height: 120px;
  margin-bottom: -120px;
  margin-top: -5px;
  margin-left: -5px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
}

.bubble-box .opponent .message .image div .more-image div p {
  margin-bottom: 0;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  line-height: 120px;
  text-align: center;
}

.bubble-box .opponent .message .image div img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}

.bubble-box .opponent .message .image p {
  padding: 10px;
  margin-top: -10px;
  color: #fff;
}

.bubble-box .opponent .time {
  float: left !important;
}

.bubble-box .you,
.bubble-box .opponent {
  margin: 40px 0;
  padding: 0 60px;
}

.bubble-box .you .message {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: right;
}

.bubble-box .you .message .text {
  background: #fcf4d2;
  border-radius: 15px 15px 0 15px;
  max-width: 70%;
  word-break: break-all;
  padding: 10px 20px;
}

.bubble-box .you .message .image {
  background: #fcf4d2;
  border-radius: 15px 15px 0 15px;
  max-width: 70%;
}

.bubble-box .you .message .image div {
  padding: 5px;
  border-radius: 15px;
  max-width: 270px;
}

.bubble-box .you .message .image div .more-image {
  width: 120px;
  height: 120px;
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.bubble-box .you .message .image div .more-image img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: -5px;
  cursor: pointer;
}

.bubble-box .you .message .image div .more-image div {
  width: 120px;
  height: 120px;
  margin-bottom: -120px;
  margin-top: -5px;
  margin-left: -5px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
}

.bubble-box .you .message .image div .more-image div p {
  margin-bottom: 0;
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  line-height: 120px;
  text-align: center;
}

.bubble-box .you .message .image div img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  margin: 5px;
}

.bubble-box .you .message .image p {
  padding: 10px;
  margin-top: -10px;
}

.bubble-box .you .message div p,
.bubble-box .opponent .message div p {
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #000;
}

.bubble-box .you .time,
.bubble-box .opponent .time {
  float: right;
  color: #fff;
  font-size: 12px;
  opacity: 0.85;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.modal-file .image {
  background: #1f2029;
  padding: 20px;
  color: #fff;
  border-radius: 10px;
}

.modal-file .image .image-list {
  overflow-y: auto;
  height: inherit;
  max-height: 400px;
  text-align: center;
  max-width: 100%;
}

.modal-file .image .image-list img {
  height: auto;
  width: auto;
  max-width: 250px;
  max-height: 100%;
  margin: 5px;
}

.modal-file .image .input-box {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

.modal-file .image input[type="text"] {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 7px 15px;
  font-family: "Nunito", sans-serif;
  width: 70%;
  min-width: 250px;
  max-width: 100%;
  font-size: 15px;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  margin-top: 5px;
}

.modal-file .image .input-box span {
  font-size: 13px;
  color: #fcf4d2;
}

.modal-view .header {
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.modal-view .header img {
  height: 55px;
  width: 55px;
  background: black;
  border: 2px solid #f2f1f2;
}

.modal-view .header .profile .opponent {
  padding-right: 160px;
}

.modal-view .header .profile .opponent .address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.modal-view .header .action{
  margin-left: -100px;
  margin-top: 10px;
}

.modal-view .header .action i {
  font-size: 20px;
  margin: 0 6px;
}

.modal-view .body {
  height: 73vh;
  line-height: 73vh;
  text-align: center;
}

.modal-view .body img {
  vertical-align: center;
  max-height: 100%;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.modal-view .footer .d-flex {
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px 5px;
}

.modal-view .footer .d-flex::-webkit-scrollbar {
  height: 5px;
}

.modal-view .footer .image-active {
  border: 3px solid #5698ca;
}

.modal-view .footer .images {
  background: black;
  min-width: 60px;
  height: 60px;
  width: 60px;
  margin: 0 3px;
}

.modal-view .footer .images img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 100% 0;
}

.context-list,
.settings-list,
.qr-box {
  position: absolute;
  z-index: 3;
  width: 255px;
  height: 150px;
  background: #000;
  color: #fff;
  padding: 7px;
}

.context-list div,
.settings-list div {
  padding: 10px 15px;
  border-radius: 3px;
  transition: 0.5s;
}

.context-list div:hover,
.settings-list div:hover {
  background: rgba(255, 255, 255, 0.2);
}

.context-list div i,
.settings-list div i {
  margin-right: 10px;
}

.settings-list,
.qr-box {
  top: 80px;
  left: 15px;
  z-index: 5;
  height: 145px;
  width: 250px;
}

.settings-list .bi-circle-fill {
  font-size: 8px;
  margin: 0 0 0 10px;
}

.qr-box {
  height: 350px;
  padding: 15px;
  background: #fff;
}

.qr-box b {
  font-family: "Poppins", sans-serif;
  color: #000;
  margin-right: 20px;
}

.qr-box span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  display: block;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.9);
}

.qr-box div {
  width: 220px;
  height: 220px;
  margin-top: 15px;
  border-radius: 20px;
  background: #f2f1f2;
  padding: 5px;
}

.qr-box div canvas {
  cursor: none;
}

@media only screen and (max-width: 374px) {
  .left-section .connections-list .connection .icon {
    display: none;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1060px) {
  .left-section .connections-list .connection .icon {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-section {
    display: none !important;
  }

  .right-section .input-chat {
    right: 0;
  }

  .right-section .profile-box img {
    width: 50px;
    height: 50px;
    margin-top: 5px;
  }

  .right-section .profile-box .bi-arrow-left {
    z-index: 1;
    color: #fff;
    margin-right: 10px;
    margin-top: 13px;
    font-size: 20px;
    display: block;
  }

  .bubble-box .you,
  .bubble-box .opponent {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1550px) {
  .list-box .right {
    padding-right: 70px;
  }
}
